import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  addWorkingStatusCallbacks,
  normaliseEventManager
} from '../util'
import { useConnectionContext } from '../context'

const buildEventRef = (instance, hasBeenTriggered, isWorking) => ({
  trigger: (payload) => instance.trigger(payload),
  hasBeenTriggered,
  isWorking,
  key: instance.key,
  canSubscribe: instance.canSubscribe,
  unregister: (...params) => instance.unregister(...params),
  registerAdhocOnSuccessListener: (...params) => instance.registerAdhocOnSuccessListener(...params),
  registerAdhocOnStartListener: (...params) => instance.registerAdhocOnStartListener(...params),
  registerAdhocOnStopListener: (...params) => instance.registerAdhocOnStopListener(...params),
  registerAdhocOnErrorListener: (...params) => instance.registerAdhocOnErrorListener(...params),
  registerAdhocOnAckListener: (...params) => instance.registerAdhocOnAckListener(...params),
  registerAdhocOnSubscribeListener: (...params) => instance.registerAdhocOnSubscribeListener(...params),
  registerAdhocOnUnsubscribeListener: (...params) => instance.registerAdhocOnUnsubscribeListener(...params),
  registerAdhocOnUnregisterListener: (...params) => instance.registerAdhocOnUnregisterListener(...params),
  unregisterAdhocListener: (...params) => instance.unregisterAdhocListener(...params)
})

export default ({ eventParam = null, isInitialiser = false }) => {
  const context = useConnectionContext()
  const dispatch = useDispatch()
  const localConnectionId = useRef(context.connection.id)
  const [localEventParam] = useState(eventParam)
  const [hasBeenTriggered, setHasBeenTriggered] = useState(false)
  const [isWorking, setIsWorking] = useState(false)
  const [classInstance, setClassInstance] = useState(() => {
    const normalisedEventManager = normaliseEventManager(localEventParam, dispatch)
    const param = {
      ...addWorkingStatusCallbacks(normalisedEventManager, () => { setIsWorking(true); setHasBeenTriggered(true) }, () => setIsWorking(false)),
      isInitialiser
    }
    return context.connection.createEventManager(param)
  })

  const [eventState, setEventState] = useState(() => buildEventRef(classInstance, hasBeenTriggered, isWorking))

  useEffect(() => {
    if (localConnectionId.current && localConnectionId.current !== context.connection.id) {
      const normalisedEventManager = normaliseEventManager(localEventParam, dispatch)
      const param = {
        ...addWorkingStatusCallbacks(normalisedEventManager, () => { setIsWorking(true); setHasBeenTriggered(true) }, () => setIsWorking(false)),
        isInitialiser
      }
      const newClassInstance = context.connection.createEventManager(param)
      setClassInstance(newClassInstance)
      setIsWorking(false)
      setHasBeenTriggered(false)
      setEventState(() => buildEventRef(newClassInstance, hasBeenTriggered, isWorking))
    }
    localConnectionId.current = context.connection.id

    return () => {
      if (localConnectionId.current && localConnectionId.current !== context.connection.id) {
        classInstance.unregister()
      }
    }
  }, [context.connection.id, classInstance])

  const eventMemo = useMemo(() => {
    eventState.isWorking = isWorking
    eventState.hasBeenTriggered = hasBeenTriggered
    return eventState
  }, [isWorking, hasBeenTriggered, eventState])

  return eventMemo
}
