import Constants from '@emerald-works/constants'
import * as EventBus from '@emerald-works/event-bus-client'
import React, { useRef, useState } from 'react'
import { SocketConnectionContext } from './context'
import useEventInitialiser from './hooks/use-event-initialiser'
import { objectToQueryString } from './util'
import Auth from '@emerald-works/react-auth'

const noop = () => { }

const InitialiserRegister = ({ children, initialisers }) => {
  // Setup initialisers listeners
  // Every function that is trigger on the "connection" event is an initialiser.
  // Data will be send to frontend if there is any listeners for that initialiser.
  if (initialisers?.length) useEventInitialiser(initialisers)
  return children
}

// create EventBus connection events.
const createEventBusConnection = ({ session, useAuthProvider, connectionParams, eventBusURL, waitForConnection, namespace, options, setIsConnected, setConnectionStatus, onConnectionChange, onClose, onOpen, onReconnect, onMaximum, onError }) => {
  return EventBus.connect({
    eventBusURL: async attempt => {
      try {
        // debugger
        const token = useAuthProvider
          ? await session.generateEventBusToken()
          : null
        // debugger
        const params = objectToQueryString({
          ...connectionParams,
          attempt,
          token
        })
        return `${eventBusURL}?${params}`
      } catch (e) {
        console.log(e)
      }
    },
    namespace,
    options,
    session,
    waitForConnection,
    onConnectionChange: status => {
      if (status === Constants.CONNECTION_SIGNAL_STATUS_GREEN) {
        setIsConnected(true)
      } else {
        setIsConnected(false)
      }
      setConnectionStatus(status)
      onConnectionChange(status)
    },
    onClose: () => {
      setIsConnected(false)
      onClose()
    },
    onOpen: () => {
      onOpen()
    },
    onReconnect: () => {
      onReconnect()
    },
    onMaximum,
    onError
  })
}

const EventBusProvider = ({
  eventBusURL,
  namespace,
  useAuthProvider = false,
  fetchUserProfile = false,
  LoadingComponent,
  waitForConnection = false,
  connectionParams,
  onOpen = noop,
  onConnectionChange = noop,
  onReconnect = noop,
  onMaximum = noop,
  onClose = noop,
  onError = noop,
  initialisers = [],
  options,
  ...props
}) => {
  const session = Auth?.useSession() || { generateEventBusToken: noop }
  const [isConnected, setIsConnected] = useState(false)
  const [connectionStatus, setConnectionStatus] = useState(Constants.CONNECTION_SIGNAL_STATUS_RED)
  const connection = useRef(null)
  const getConnection = () => {
    if (connection.current === null) {
      connection.current = createEventBusConnection({ session, useAuthProvider, waitForConnection, connectionParams, eventBusURL, namespace, options, setIsConnected, setConnectionStatus, onConnectionChange, onClose, onOpen, onReconnect, onMaximum, onError })
    }
    return connection.current
  }

  const reloadConnection = async () => {
    const { session: newSession, updateCallback } = await session.updateSessionContext()
    getConnection().reloadConnection({ ...session, ...newSession }, updateCallback)
  }

  const reconnect = () => {
    getConnection().reconnect()
  }

  const childrenElement = () => (
    <SocketConnectionContext.Provider
      value={{
        isConnected,
        reloadConnection,
        reconnect,
        connection: getConnection(),
        connectionStatus
      }}
    >
      <InitialiserRegister initialisers={initialisers}>
        {props.children}
      </InitialiserRegister>
    </SocketConnectionContext.Provider>
  )

  if (isConnected || !waitForConnection) {
    return childrenElement()
  }

  getConnection()
  return LoadingComponent
}

export default EventBusProvider
