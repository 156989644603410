import Sockette from 'sockette-dynamic-url'
import EventManager from './event-manager'
import Constants from '@emerald-works/constants'
import zlib from 'zlib'
import debug from 'debug'
import { v4 as uuid } from 'uuid'

const logger = debug(`${Constants.COMPONENT_EVENT_BUS_CLIENT}:socket-manager`)
const noop = () => { }
const DEFAULT_OPTIONS = {
  timeout: 5e3,
  maxAttempts: 10,
  triggerTimeout: 30000
}

export default class SocketManager {
  constructor (connectionParams) {
    this.eventManagers = {}
    this.initialisers = {}
    this.connectionStatus = Constants.CONNECTION_SIGNAL_STATUS_RED
    this.payloadsWaitingForConnection = []
    this.opts = { ...DEFAULT_OPTIONS }
    this.localCache = {}
    this.connectionParams = connectionParams
    this.session = this.connectionParams.session
    this.waitForConnection = this.connectionParams.waitForConnection
  }

  promise () {
    // return Promise
  }

  // red: no connection
  // yellow: connected but not ready to send messages
  // green: connected and ready to use
  isConnected () {
    return this.connectionStatus
  }

  _cleanPayloadWaitingForConnection () {
    this.payloadsWaitingForConnection = []
  }

  _addPayloadWaitingForConnection (payload) {
    this.payloadsWaitingForConnection.push(payload)
  }

  _processPayloadWaitingForConnection () {
    if (
      this.ws &&
      this.connectionStatus === Constants.CONNECTION_SIGNAL_STATUS_GREEN
    ) {
      for (const payload of this.payloadsWaitingForConnection) {
        this.sendJson(payload)
      }
      this._cleanPayloadWaitingForConnection()
    } else {
      console.log(
        'Connection is not ready yet. Not processing payloads in queue to avoid infinity loop.'
      )
    }
  }

  // Send Ping message to prevent API GATEWAY to idle Timeout
  _setPreventIdleTimeout () {
    this.idleTimeout = setTimeout(() => {
      this.sendJson({
        eventName: Constants.PING_EVENT
      })
    }, Constants.PING_REQUEST_IDLE_TIME_MS)
  }

  _resetPreventIdleTimeout () {
    if (this.idleTimeout) {
      clearTimeout(this.idleTimeout)
    }

    this._setPreventIdleTimeout()
  }

  _setConnectionStatus (status) {
    this.connectionStatus = status
    this.listeners.onConnectionChange(this.connectionStatus)
    if (status === Constants.CONNECTION_SIGNAL_STATUS_GREEN) {
      this._resetPreventIdleTimeout()
      this._processPayloadWaitingForConnection()
    }
  }

  _processInitialiser (message) {
    if (!this.initialisers) {
      return
    }
    const event = this.initialisers[message.eventName]
    if (event) {
      if (message.payload && message.payload.error) {
        event.callListeners('onError', message.payload.error)
      } else {
        event.callListeners('onSuccess', message)
      }
    }
  }

  _processInternal (message) {
    logger('processInternal message %j', message)
    const event = this.eventManagers[message.key]
    if (event) {
      if (message.eventName === Constants.SUBSCRIBE_EVENT) {
        event.callListeners('onSubscribe', message)
      }
      if (message.eventName === Constants.UNSUBSCRIBE_EVENT) {
        event.callListeners('onUnsubscribe', message)
      }
    }
  }

  _processError (message) {
    console.log('processing error', message)
    if (message.payload?.error?._actions) {
      const actions = message.payload.error._actions
      if (actions.reconnect) {
        console.log('reconnecting')
        this.reconnect()
      }
      if (actions.close) {
        this.ws.close()
      }
    }
  }

  _processEventResponse (message) {
    if (!this.eventManagers) {
      return
    }

    const event = this.eventManagers[message.key]
    if (event) {
      event.cancelTimeout()
      if (message.payload && message.payload.error) {
        event.callListeners('onError', message.payload.error)
      } else {
        event.callListeners('onSuccess', message)
        // if not realtime, unregister
      }
      event.isWorking = false
      event.callListeners('onStop', event)
    }
  }

  _processEventSideEffect (message) {
    const targetEvent = Object.values(this.eventManagers).find(
      event => event.eventName === message.eventName
    )

    if (targetEvent) {
      this._processEventResponse({
        ...message,
        key: targetEvent.key
      })
    } else if (
      Object.prototype.hasOwnProperty.call(this.initialisers, message.eventName)
    ) {
      this._processInitialiser(message)
    } else {
      console.info(`
        Trying to process event sideEffect for event name: ${message.eventName} but no event was found for it in eventManagers or initialisers. 
        Create a event to handle it in the component context or add it as a initialiser.
      `)
    }
  }

  connect (updateSessionCallback) {
    this.eventBusURL = this.connectionParams.eventBusURL
    this.namespace = this.connectionParams.namespace
    this.id = uuid()

    this.listeners = {
      onOpen: (...args) => {
        logger('onOpen', args)
        this.connectionParams.onOpen(args)
      },
      onConnectionChange: connectionStatus => {
        logger('onConnectionChange', connectionStatus)
        this.connectionParams.onConnectionChange(connectionStatus)
      },
      onReconnect: (...args) => {
        logger('onReconnect', args)
        this.connectionParams.onReconnect(args)
      },
      onMaximum: (...args) => {
        logger('onMaximum', args)
        this.connectionParams.onMaximum(args)
      },
      onClose: (...args) => {
        logger('onClose', args)
        this.connectionParams.onClose(args)
      },
      onError: (...args) => {
        logger('onError', args)
        console.log('socket cannot connect', args)
        this.connectionParams.onError(args)
      }
    }

    this.opts = {
      ...DEFAULT_OPTIONS,
      ...this.connectionParams.options
    }

    this.sockette = new Sockette(this.eventBusURL, {
      ...this.opts,
      onopen: event => {
        this.ws = event.target
        this.session = this.connectionParams.session
        if (updateSessionCallback) updateSessionCallback()
        this._setConnectionStatus(Constants.CONNECTION_SIGNAL_STATUS_YELLOW)
        this.listeners.onOpen()
      },
      onmessage: ({ data }) => {
        this._resetPreventIdleTimeout()
        const message = JSON.parse(data)
        if (message.payload) {
          const result = this.handleReceivingSplitted(message)
          if (!result) return // wait for the other pieces to come
          message.payload = result
        }

        message.payload = message.payload ? this.handleDecompression(message.payload) : null

        logger('onmessage type %o message %j', message.type, message)
        const onMessageHandlers = {
          [Constants.MESSAGE_TYPE_CONNECTION_SIGNAL]: () => {
            this._setConnectionStatus(message.payload.connectionSignal)
          },
          [Constants.MESSAGE_TYPE_RESPONSE]: () => {
            // Default event result
            if (message.key) {
              this._processEventResponse(message)
            } else {
              // sideEffect eventLM result
              this._processEventSideEffect(message)
            }
          },
          [Constants.MESSAGE_TYPE_ACK]: () => {
            if (!this.eventManagers) {
              return
            }

            const eventLM = this.eventManagers[message.key]
            if (eventLM) {
              eventLM.callListeners('onAck', message.payload)

              if (
                message.payload.responseNeedsSubscribe &&
                message.payload.responseDestination !== eventLM.name
              ) {
                eventLM.cancelTimeout()
                eventLM.callListeners('onStop', eventLM)
                eventLM.isWorking = false
              }
            }
          },
          [Constants.MESSAGE_TYPE_INITIALISER]: () => {
            this._processInitialiser(message)
          },
          [Constants.MESSAGE_TYPE_INTERNAL]: () => {
            this._processInternal(message)
          },
          [Constants.MESSAGE_TYPE_ERROR]: () => {
            this._processError(message)
          }
        }
        logger('message type %o', message.type)
        if (
          Object.prototype.hasOwnProperty.call(onMessageHandlers, message.type)
        ) {
          onMessageHandlers[message.type]()
        } else {
          logger('No processing for message', message)
        }
      },
      onreconnect: this.listeners.onReconnect || noop,
      onmaximum: this.listeners.onMaximum || noop,
      onclose: event => {
        this.ws = undefined
        if (
          this.connectionStatus !== Constants.CONNECTION_SIGNAL_STATUS_RELOADING
        ) {
          this._setConnectionStatus(Constants.CONNECTION_SIGNAL_STATUS_CLOSED)
        }
        this.listeners.onClose ? this.listeners.onClose(event) : noop()
        console.log('onclose', event)
        if (event.code === 1005 || event.code === 1001) {
          this.waitForConnection ? this.reconnect(event) : noop()
        }
      },
      onerror: err => {
        this.listeners.onError ? this.listeners.onError(err) : noop()
      }
    })
  }

  disconnect () {
    this._setConnectionStatus(
      Constants.CONNECTION_SIGNAL_STATUS_MANUALLY_DISCONNECTED
    )
    setTimeout(() => {
      try {
        if (this.ws) this.ws.close()
      } catch (err) {
        // TODO narrow down possible errors
        console.log('err', err)
      }
    }, 100)
  }

  // close the connection, opens a new one and update the session object
  reloadConnection (newSession, updateSessionCallback) {
    if (this.ws) this.ws.close()
    this.connectionParams.session = newSession
    this._setConnectionStatus(Constants.CONNECTION_SIGNAL_STATUS_RELOADING)
    this.connect(updateSessionCallback)
  }

  // opens a new connection (attempt counter increases) again using the same parameters. The old connection isn't closed
  reconnect () {
    if (this.connectionStatus !== Constants.CONNECTION_SIGNAL_STATUS_RELOADING) {
      this._setConnectionStatus(Constants.CONNECTION_SIGNAL_STATUS_RELOADING)
      this.sockette.reconnect()
    }
  }

  createEventManager (eventParams) {
    // initialiser can't subscribe
    const initialiserParams = {
      ...eventParams,
      connection: this,
      canSubscribe: false,
      subscribeOnInit: false
    }
    const nonInitialiserParams = {
      connection: this,
      ...eventParams
    }
    const normalizedParams = eventParams.isInitialiser
      ? initialiserParams
      : nonInitialiserParams

    const event = new EventManager(normalizedParams)
    if (eventParams.isInitialiser) {
      this.initialisers[event.eventName] = event
    } else {
      this.eventManagers[event.key] = event
    }
    return event
  }

  unregister (event) {
    if (event.isInitialiser) {
      delete this.initialisers[event.eventName]
    } else {
      delete this.eventManagers[event.key]
    }
  }

  sendJson (Data) {
    if (
      this.ws &&
      this.connectionStatus === Constants.CONNECTION_SIGNAL_STATUS_GREEN
    ) {
      const compressed = this.handleCompression(Data.payload)
      const packets = this.handleSendingSplitted(compressed, Data)
      packets.forEach(packet => this.ws.send(packet))
      this._resetPreventIdleTimeout()
    } else {
      this._addPayloadWaitingForConnection(Data)
    }
  }

  handleCompression (payload) {
    let newData = JSON.stringify(payload)
    if (!process.env.REACT_APP_DISABLE_WEB_SOCKET_COMPRESSION && newData) {
      newData = zlib.deflateSync(newData).toString('base64')
    }
    return newData
  }

  handleDecompression (data) {
    if (typeof data === 'string') {
      if (!process.env.REACT_APP_DISABLE_WEB_SOCKET_COMPRESSION && data) {
        return JSON.parse(zlib.inflateSync(Buffer.from(data, 'base64')).toString())
      }
      return JSON.parse(data)
    }
    return data
  }

  handleSendingSplitted (payload, Data) {
    if (payload && payload.length >= Constants.WEBSOCKET_PAYLOAD_SIZE) { // should split payload
      const splitted = payload.match(new RegExp(`.{1,${Constants.WEBSOCKET_PAYLOAD_SIZE}}`, 'g'))
      const streamId = uuid()
      return splitted.map((item, idx) =>
        JSON.stringify({ ...Data, payload: item, piece: idx, pieces: splitted.length, isSplitted: true, streamId })
      )
    } else {
      return [JSON.stringify({ ...Data, payload })]
    }
  }

  handleReceivingSplitted (message) {
    const messageCopy = { ...message }
    if (messageCopy.isSplitted) {
      const cacheKey = `${messageCopy.streamId}-${messageCopy.type}`
      if (!this.localCache[cacheKey]) {
        this.localCache[cacheKey] = []
      }
      this.localCache[cacheKey].push(messageCopy)
      if (this.localCache[cacheKey].length < messageCopy.pieces) {
        return null
      }
      const result = this.localCache[cacheKey].sort((a, b) => a.piece - b.piece).map(i => i.payload).join('')
      this.localCache[cacheKey] = []
      return result
    } else {
      return messageCopy.payload
    }
  }
}
